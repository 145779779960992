import WD from "images/services/WD.jpg";
import SD from "images/services/SD.jpg";
import IC from "images/services/IC.jpg";
import AM from "images/services/AM.jpg";
import CE from "images/E-learning/CE.jpg";
import MAD from "images/services/MAD.jpg";
import SEO from "images/services/SEO.jpg";
import VT from "images/E-learning/VT.jpg";
import SMM from "images/services/SMM.jpg";
import NF from "images/E-learning/NF.jpg";
import DSM from "images/E-learning/DSM.jpg";
import CCP from "images/E-learning/CCP.jpg";
import IIM from "images/E-learning/IIM.jpg";
import NSM from "images/E-learning/NSM.jpg";

export const ElearningData = [
  {
    id: 1,
    heading: "Data Server Management",
    subheading: "Efficient Data Handling",
    description:
      "Learn to manage and maintain data servers effectively. From setup to security, ensure seamless data storage and retrieval for optimal performance.",
    primaryButtonText: "More Info",
    imageSrc: DSM,
    textOnLeft: true,
  },
  {
    id: 2,

    heading: "Networking Fundamentals",
    subheading: "Connectivity Essentials",
    description:
      "Master the basics of networking - from protocols to routing. Build a strong foundation in setting up, configuring, and securing network environments.",
    primaryButtonText: "More Info",
    imageSrc: NF,
    textOnLeft: false,
  },
  {
    id: 3,
    heading: "Cybersecurity Essentials",
    subheading: "Digital Defense Strategies",
    description:
      "Understand cybersecurity threats and defenses. Learn to safeguard networks, data, and systems against cyber attacks with encryption and monitoring",
    primaryButtonText: "More Info",
    imageSrc: CE,
    textOnLeft: true,
  },
  {
    id: 4,
    heading: "MEAN/MERN Stack Web Development",
    subheading: "IT Service",
    description:
      "Dive into MEAN/MERN Stack - MongoDB, Express.js, Angular,React and Node.js. Learn to craft dynamic, full-stack web applications with seamless user experiences.",
    primaryButtonText: "More Info",
    imageSrc: WD,
    textOnLeft: false,
  },
  {
    id: 5,
    heading: " Mobile App Development with MEAN/MERN",
    subheading: "IT Service",
    description:
      "Master cross-platform mobile app creation using MEAN technologies. Build apps that deliver exceptional performance and real-time functionality.",
    primaryButtonText: "More Info",
    imageSrc: MAD,
    textOnLeft: true,
  },
  {
    id: 6,
    heading: "Software Development",
    subheading: "IT Service",
    description:
      "Tailor software solutions to optimize your operations, leveraging technology to enhance efficiency and meet specific business goal",
    primaryButtonText: "More Info",
    imageSrc: SD,
    textOnLeft: false,
  },
  {
    id: 7,
    heading: "E-commerce with MEAN",
    subheading: "IT Service",
    description:
      "Benefit from expert guidance to formulate IT strategies that enhance productivity, security, and align technology with your business objectives",
    primaryButtonText: "More Info",
    imageSrc: IC,
    textOnLeft: true,
  },

  {
    id: 8,
    heading: "Search Engine Optimization (SEO)",
    subheading: "Digital Marketing Service",
    description:
      "Enhance online visibility and drive organic traffic through strategic search engine optimization techniques tailored to your target audience.",
    primaryButtonText: "More Info",
    imageSrc: SEO,
    textOnLeft: false,
  },
  {
    id: 9,
    heading: "Social Media Marketing",
    subheading: "Digital Marketing Service",
    description:
      "Amplify brand presence on social platforms, engaging users and building strong connections through well-crafted content and interactions.",
    primaryButtonText: "More Info",
    imageSrc: SMM,
    textOnLeft: true,
  },
  {
    id: 10,
    heading: "Affiliate Marketing",
    subheading: "Digital Marketing Service",
    description:
      "Collaborate with affiliates to promote your offerings and gain wider exposure.",
    primaryButtonText: "More Info",
    imageSrc: AM,
    textOnLeft: false,
  },

  {
    id: 11,

    heading: "Cloud Computing Principles",
    subheading: "Master Cloud Technologies",
    description:
      "Dive into cloud computing - from SaaS to IaaS. Understand virtualization, deployment models, and data migration for efficient cloud solutions.",
    primaryButtonText: "More Info",
    imageSrc: CCP,
    textOnLeft: true,
  },
  {
    id: 12,

    heading: "Network Security and Monitoring",
    subheading: "Securing the Network",
    description:
      "Delve into network security strategies - from firewalls to intrusion detection. Learn to monitor and protect networks against emerging threats.",
    primaryButtonText: "More Info",
    imageSrc: NSM,
    textOnLeft: false,
  },
  {
    id: 13,

    heading: "IT Infrastructure Management",
    subheading: "Efficient Operations",
    description:
      "Gain expertise in managing IT infrastructure. From servers to devices, ensure optimal performance, uptime, and troubleshooting.",
    primaryButtonText: "More Info",
    imageSrc: IIM,
    textOnLeft: true,
  },
  {
    id: 14,

    heading: "Virtualization Technologies",
    subheading: "Optimize Resource Usage",
    description:
      "Explore virtualization for efficient resource utilization. Learn to deploy and manage virtual machines, enhancing scalability and flexibility.",
    primaryButtonText: "More Info",
    imageSrc: VT,
    textOnLeft: false,
  },
  // {
  //   id: 15,
  //   heading: "CompTIA A+ Certification Training (Core1 & Core2)",
  //   subheading: "CompTIA Testing",
  //   description:
  //     "Comprehensive training in hardware, software, networking, and troubleshooting, preparing individuals for CompTIA A+ Core1 and Core2 certification exams.",
  //   primaryButtonText: "More Info",
  //   // imageSrc: VT,
  //   textOnLeft: true,
  // },
];
