/** @format */

import { ToastContainer, toast } from "react-toastify";

const options = {
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const Container = () => (
  <ToastContainer
    position="top-center"
    autoClose={1000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    // pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

export const Toast = ({ message, position = "top-center", type = "success" }) => {
  if (type === "success")
    return toast.success(message, {
      position,
      ...options,
    });
  else if (type === "info")
    return toast.info(message, {
      position,
      ...options,
    });
  return toast.error(message, {
    position,
    ...options,
  });
};

export const AlertBox = ({ message, type = "error" }) => Toast({ message, type });
