export const StatData = {
  subheading: "",
  heading: "Over 100 Projects Completed",
  description:
    "Successfully executed ADCS Tech project: streamlined infrastructure, optimized connectivity, enhanced security, and vibrant tech ecosystem, resulting in unprecedented innovation and growth.  ",
  stats: [
    {
      key: "Clients",
      value: "100+",
    },
    {
      key: "Revenue",
      value: "$1M+",
    },
    {
      key: "Employees",
      value: "10+",
    },
  ],
};
